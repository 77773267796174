<template>

  <!-- Start Content-->
  <div>
        <headers />                   
    <div class="modal-body" style="padding-top:70px;" >
    <h2><div class="lang" v-html="lan.function_update"></div></h2>
      <div  class="col-12 row" style="margin-top:10px;"  >
        <div class="col-xl-12 ">
          <h4><div class="lang" v-html="lan.title"></div></h4>
          <input :class="'form-control col-10 col-md-12'" v-model="cur_obj.subject" :placeholder="lan.subject" type="text" />
          <h4><div class="lang" v-html="lan.content"></div></h4>
          <div id="mail-body"></div>  
          <h4><div class="lang" v-html="lan.mail_template"></div></h4>
          <select :class="'form-control col-10 col-md-12'" v-model="cur_obj.mail_template" :placeholder="lan.mail_template">
            <option value="cbreai_functions">cbreai functions</option>
            <option value="cbreai_contents">cbreai contents</option>
          </select>
          
          <h4><div class="lang" v-html="lan.version"></div></h4>
          <input :class="'form-control col-10 col-md-12'" v-model="cur_obj.version" :placeholder="lan.version" type="text" />
          <h4><div class="lang" v-html="lan.author"></div></h4>
          <input :class="'form-control col-10 col-md-12'" v-model="cur_obj.author" :placeholder="lan.author" type="text" />
           <h4><div class="lang" v-html="lan.email"></div></h4>    
          <div class="box" style="clear:both;display:table;">
            <input type="checkbox" @click="checkall()"> <div class="lang" v-html="lan.all"></div><br>
            <div class="line" v-for="(item,index) in mails" :key="index">
            <input type="checkbox" :value="item['email']" :checked="to_emails.includes(item['email'])" @click="checked(item['email'])" > {{item['email']}}
            </div>
          </div> 
          <h4><div class="lang" v-html="lan.cc_email"></div></h4>
          <input :class="'form-control col-10 col-md-12'" v-model="cur_obj.cc_email" :placeholder="lan.cc_email" type="text" />
            <div class="row d-flex justify-content-end align-items-center mt-2 mb-3 px-2">
              <div class=" pr-0 "> 
                <button type="button" class="btn btn-primary" style="float:right" @click="SaveParams(2)"><div class="lang" v-html="lan.save_send"></div></button> 
                <button type="button" class="btn btn-primary" style="float:right" @click="SaveParams(1)"><div class="lang" v-html="lan.save"></div></button> 
              </div>
            </div>
        </div>
      </div>
    </div>
                               
                               
   
  </div> <!-- container -->

</template>

<script>
import Headers from '@/components/Headers.vue'
import axios from 'axios';
import { inject } from 'vue';
import E from 'wangeditor';
import router from '@/router';

let editor=null;
export default {
  name: 'ListTableEmail',
 
  props: {
    viewParams: {
      name: {
        type: String,
        default: ''
      },
      apiUrl: {
        type: String,
        default: '',
      },
      dataType: {
        type: String,
        default: 1,
      },
      articleView: String,
      articleParamId: String
    },
  },
  components: {
    Headers
  },
  data() {
    return {
      cur_obj:{tomail:'',subject:'',content:'',author:'',version:'',cc_email:'',mail_template:'cbreai_functions'},
      to_emails:[],
      mails:[],
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      data_type:'function',
      cur_lan:'jp'
    }
  },
  mounted() {
    const { currentRoute } = router;
    this.data_type=currentRoute.value.query.data_type;
    
    this.getMail();
    //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data
    editor = new E('#mail-body')
     editor.config.menus = [
                        'head',
                        'bold',
                        'fontSize',
                        // 'fontName',
                        'italic',
                        'underline',
                        'strikeThrough',
                        'indent',
                        'lineHeight',
                        'foreColor',
                        'backColor',
                        'link',
                        'list',
                        'justify',
                        'quote',
                        'image',
                        'video',
                        'table',
                        'code',
                        'splitLine',
                        'undo',
                        'redo',
                    ]
                    // select Lang, TODO japanese
    editor.config.lang = 'en';
    editor.i18next = window.i18next;
    editor.config.placeholder = this.lan.content;
    editor.create();
   if(currentRoute.value.query.cid!=undefined ){
      this.data_type=currentRoute.value.query.data_type;
      this.getinfo(currentRoute.value.query.cid,currentRoute.value.query.data_type);
    }
  },
  methods: {
    getMail:function(){
      var that=this;
      axios.get('/v1/cbreai_user_list',{
          params: {limit:1000}
        })
        .then(function(response){
          
            that.mails=response.data.data;
        })
        .catch(error => {
         
          console.log(error)
        });
    },
    getinfo:function(cid,data_type){
      var that=this;
      axios
        .get('/v1/notification_'+data_type+'_info',{
          params: {
            id:cid
          }
        })
        .then(function(response){
          if(response.data.id!=undefined){
            var info=response.data
            that.cur_obj={tomail:'',subject:info[data_type=='function' ? "function_name":"content_name"],content:info[data_type=='function' ? "function_desc":"content_desc"],author:info.author,version:info.version,cc_email:'',mail_template:'cbreai_functions'}
            editor.txt.html(that.cur_obj.content);
          }
        })
        .catch(error => {
         
          console.log(error)
        });
    },
    SaveParams: function(type) {
      this.isLoading2 = true
      this.cur_obj.tomail=this.to_emails.join(",");
      
      this.cur_obj.content=editor.txt.html();
      var param=new FormData();
       param.append('email', this.cur_obj.tomail); 
       param.append('subject',  this.cur_obj.subject); 
       param.append('content',  this.cur_obj.content);
       param.append('author',  this.cur_obj.author); 
       param.append('cc_email',  this.cur_obj.cc_email); 
       param.append('mail_template',  this.cur_obj.mail_template); 
       param.append('version',  this.cur_obj.version);
       param.append('sendtype',  type);
       param.append('service',  'cbreai.com');
       param.append('key','c612ae5694dfd1e73e3c705f9a051ef92d604c4af080e75a67d9685f1d663357');
       console.log(param)
      axios.post(process.env.VUE_APP_API2_URL_BASE+'/NotificationSendEmail',param,{headers:{'Content-Type': 'multipart/form-data'}})
        .then(function(response) {
          console.log(response);
         //location.href='/#/notification_list?data_type='+this.data_type
         
        })
        .catch(error => {
          console.log(error)
        });
    },
    checkall:function(){
      if(this.to_emails.length==this.mails.length){
        this.to_emails=[];
      }else{
        this.to_emails=this.mails.map(function (item){
          return item['email']
        })
      }
    },
    checked:function(item){
      var aa=this.to_emails.indexOf(item);
      if(aa>-1){
        this.to_emails.splice(aa,1);
      }else{
        this.to_emails.push(item)
      }
    }
  },
  watch: {
    
  $route(){
    this.cur_lan=localStorage.getItem('cur_lan') !="en" ? "jp":"en";
    //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data['menu_data_'+this.cur_lan]
  },
    isLoading: function() {
      if(!this.isLoading) {
        this.$nextTick(() => {
          window.parent.postMessage(['h'],window.location.host)
        })
      } else {
        //ローディング開始のタイミングでisFocusをリセット
        this.isFocus = -1
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.popup-modal {
  display: block;
      background: rgba(0,0,0,0.8);
}

.modal-body{max-height: 740px;overflow: auto;}
.btn-bottom{position:absolute; bottom: 10px;right:10px}
.form-inline {
  flex-wrap: nowrap;
}

.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {

  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: .75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: .5em;
    width: 100%;
  }

}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: .15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -.5rem;
  top: -.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}
.line{float: left;
    width: 33%;}
.dropdown-menu.show li {
  flex: 0 1 32.5%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

@media screen and (max-width: 768px) {

  .dropdown-menu.show li {
    flex-basis: 50%;
  }

}

@media screen and (max-width: 500px) {

  .dropdown-menu.show li {
    flex-basis: 100%;
  }

}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover, table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  background: #fff;
  box-shadow: 0 -.1rem #dee2e6 inset;
  border-bottom: none;
    color:#003f2d;
  position: sticky;
  top: -1px;
  z-index: 8;
}
.table.table-striped td {
  min-width: 60px;
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  padding: .35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: .15s;
}


.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}
 .table-data-container div, .table-data-container{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    width: auto;
}


.table-rep-plugin {
  flex: 1;
}

@media screen and (max-width: 768px) {

  .responsive-table-plugin {
    flex-direction: column;
     height: 90vh;
    width: 100%;
  }

  .table-responsive {
    height: 100%;
    max-height: none;
  }

}

.responsive-table-plugin tbody th {
  font-weight: bold;
  min-width: 12.5em;
  white-space: normal !important;
  width: 25%;
  word-break: break-all;
}

.responsive-table-plugin tbody td {
  white-space: normal !important;
  word-break: break-word;
}

.invalid-feedback {
  display: block;
}
.lline{  float: left;
    word-break: break-all;
    min-height: 50px;
    line-height: 40px;}
.lline div{float: left;width: 62%;}
.lline .list-left{width:38%;float: left;}

.modal-dialog{max-width: 1400px;}
.title,h4{color:#003f2d;}
.pr-0 .btn,.sendmail{margin-left:15px}
.list-info{max-height: 550px; overflow-y:auto;}
.text-body{min-height:350px !important;}
.btn-primary{margin-right: 0px;}
.table-striped thead{display: block;}
.table-striped th,.table-striped td{width: 150px !important; min-width: 150px !important;max-width: 150px !important; overflow: hidden;}
.table-striped th.max,.table-striped td.max{width: 200px !important; max-width: 200px !important; overflow: hidden;}
.table-striped tbody{display: block;overflow-y: auto;overflow-x: hidden; width: 100%;height:400px;}
</style>